body {
  margin: 0;
  padding: 0;
  height: auto;
  width: 100vw;
}


body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

