/* Container for the lists */
.lists {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.inner-list {
  flex: 1 0 auto;
  width: 100%;
  /* Make each item the same width as the container */
  scroll-snap-align: start;
  /* Align items to the start of the container */
  /* Other styling for the items */
}

.lists::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* App.css or ListManager.module.css */
.toggle-container {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1000;
}