/* ListManager.module.css */
.list-manager {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  /* padding: 10px; Add padding to create space for the plus button */
  position: relative;
  /* Required for positioning the plus button */
}

.inner-list {
  flex: 1 0 auto;
  width: 100%;
  scroll-snap-align: start;
}

.list-manager::-webkit-scrollbar {
  display: none;
}

/* Styling for the plus button */
.add-button {
  position: fixed;
  /* Change to fixed */
  top: 10px;
  /* Adjust top position as needed */
  left: 20px;
  /* Adjust left position as needed */
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #0056b3;
}

/* Styling for the pop-up */
.list-popup {
  position: fixed;
  /* Change to fixed */
  top: 20px;
  /* Adjust top position as needed */
  left: 20px;
  /* Adjust left position as needed */
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
  /* Ensure it's above other content */
}

.list-popup input[type="text"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.list-popup button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-popup button:hover {
  background-color: #0056b3;
}