/* Center the title */
.title {
  text-align: center;
}

/* Center the entire content */
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  justify-content: flex-start;
}

/* Style for the plain input */
.plain-input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  text-align: center;
}

/* Style for the product card */
.product-card {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 8px;
  width: 60vw;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* On hover */
}

/* Center the clear list button and give it a background */
.clear-list {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f2f2f2; */
  padding: 12px;
  margin-top: 20px;
}

/* Style for the clear list button */
.clear-button {
  border: none;
  background-color: #ff4f4f;
  color: #fff;
  border-radius: 8px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  /* Add space to the right of button */
  margin-left: 10px;
  margin-right: 10px;
}

.clear-button:hover {
  background-color: #ff3333;
}

/* Additional styling for the list of products */
.products-list {
  list-style: none;
  padding: 0;
  text-align: center;
  margin-top: 15px;
}

.add-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  margin-bottom: 16px;
  width: 60vw;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for the add product input */
.add-product input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}

/* Add this to your List.css */
.product-card {
  transition: background-color 0.0s ease;
}

.product-card.dragging {
  background-color: lightgray; /* Change the background color when dragging */
}

.product-card.highlight {
  background-color: rgb(247, 247, 227); /* Change the background color for the highlighted item */
}
